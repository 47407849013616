import listAPI from "@/api/listAPI.js";
import Header from "@/components/Header.vue";
import SubHeader from "@/components/SubHeader.vue";
import patientAPI from "../../api/patientAPI";
import store from "@/store/index";
import mastersAPI from "../../api/mastersAPI";
import notifications from "@/components/common/notifications";
import validations from "@/validations/index";
import permissions from "@/api/permissions.js";

export default {
  components: {
    Header,
    SubHeader,
    notifications,
  },
  data: () => ({
    isPostBack: false,
    formData: {
      patient_id: null,
      primary_insurance_certificate: null,
      primary_insurance_pay_grp: null,
      secondary_insurance_certificate: null,
      secondary_insurance_pay_grp: null,
    },
    formInsuranceMaster: {
      id: null,
      carrier_name: null,
      address1: null,
      city: null,
      state_id: null,
      zip_code: null,
    },

    vrInsurancePrimary: {},
    vrCarrierPrimary: [],
    isLoadingCarrierPrimary: false,
    vrKeyWordCarrierPrimary: null,

    //Secondry
    vrInsuranceSecondry: {},
    vrCarrierSecondry: [],
    isLoadingCarrierSecondry: false,
    vrKeyWordCarrierSecondry: null,

    //popup
    addInsurance: false,

    //---------------------------------------------------
    //Used for permission handling
    //---------------------------------------------------
    permissions: {},
    //---------------------------------------------------
  }),
  watch: {
    formData: {
      handler: function () {
        //store.state.unSavedChanges = true;
      },
      deep: true,
    },
    //Primary
    vrKeyWordCarrierPrimary(val) {
      if (val == null) {
        this.vrInsurancePrimary = {};
        this.vrCarrierPrimary = [];
        return;
      }
      this.searchInsurer(val, "vrCarrierPrimary", "isLoadingCarrierPrimary");
    },
    //Secondry
    vrKeyWordCarrierSecondry(val) {
      if (val == null) {
        this.vrInsuranceSecondry = {};
        this.vrCarrierSecondry = [];
        return;
      }
      this.searchInsurer(val, "vrCarrierSecondry", "isLoadingCarrierSecondry");
    },
  },
  methods: {
    saveData() {
      this.formData.primary_insurance = this.vrInsurancePrimary;
      this.formData.secondary_insurance = this.vrInsuranceSecondry;

      //----------------------------------------------
      //Required field validations
      let isValid = true;

      if (isValid) {
        isValid = validations.hasValue(this.vrInsurancePrimary.id);
      }
      if (!isValid) {
        this.vrNotify.type = store.state.required.type;
        this.vrNotify.message = store.state.required.message;
        this.vrNotify.visible = store.state.required.visible;
        return false;
      }
      //----------------------------------------------

      //savePatientSaveInsurance
      patientAPI
        .savePatientSaveInsurance(this.formData)
        .then((response) => {
          if (response.data !== null) {
            this.vrNotify.type = response.data.error_type;
            this.vrNotify.message = response.data.message;
            this.vrNotify.visible = true;

            //Reset the flag
            //store.state.unSavedChanges = false;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          //this[vrLoadingControlName] = false;
        });
    },
    //-------------------------------------------------------
    //Search Insurer
    //-------------------------------------------------------
    searchInsurer(val, vrControlName, vrLoadingControlName) {
      //Do not load data on initial page load
      if (!this.isPostBack) return;

      // Items have already been requested
      if (this[vrLoadingControlName]) return; //Return

      if (val === null) return; //Return

      this[vrLoadingControlName] = true; //initiate loading

      listAPI
        .getInsuranceList({ SearchString: val })
        .then((response) => {
          if (response.data.results !== null)
            this[vrControlName] = response.data.results.InsuranceCarrier;
          else this[vrControlName] = [];
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this[vrLoadingControlName] = false;
        });

      this[vrLoadingControlName] = false;
    }, //End searchDiagnosis
    //-------------------------------------------------------

    saveInsuranceMaster() {
      //----------------------------------------------
      //Required field validations
      let isValid = true;

      if (isValid) {
        isValid = validations.hasValue(this.formInsuranceMaster.carrier_name);
      }
      if (!isValid) {
        this.vrNotify.type = store.state.required.type;
        this.vrNotify.message = store.state.required.message;
        this.vrNotify.visible = store.state.required.visible;
        return false;
      }
      //----------------------------------------------

      mastersAPI
        .saveInsuranceMaster(this.formInsuranceMaster)
        .then((response) => {
          if (response.data !== null) {
            this.vrNotify.type = response.data.error_type;
            this.vrNotify.message = response.data.message;
            this.vrNotify.visible = true;

            if (response.data.status) {
              this.formInsuranceMaster = {};
              this.addInsurance = false;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          //this[vrLoadingControlName] = false;
        });
    },
  },
  computed: {
    vrState() {
      return store.state.arrStates;
    },
    vrNotify() {
      return store.state.notify;
    },
    vrRedirectURL() {
      return store.state.redirectURL;
    },
  },
  mounted() {
    this.formData.patient_id = store.state.patientId;

    patientAPI
      .getPatientGetInsurance({ PatientId: this.formData.patient_id })
      .then((response) => {
        if (response.data.results !== null) {
          if (response.data.results.primary_insurance.id != null) {
            this.vrInsurancePrimary = response.data.results.primary_insurance;
          }

          if (response.data.results.secondary_insurance.id != null) {
            this.vrInsuranceSecondry =
              response.data.results.secondary_insurance;
          }

          this.formData.primary_insurance_certificate =
            response.data.results.primary_insurance_certificate;
          this.formData.primary_insurance_pay_grp =
            response.data.results.primary_insurance_pay_grp;

          this.formData.secondary_insurance_certificate =
            response.data.results.secondary_insurance_certificate;
          this.formData.secondary_insurance_pay_grp =
            response.data.results.secondary_insurance_pay_grp;
        }
      })
      .then(() => {
        if (this.vrInsurancePrimary !== null) {
          this.vrCarrierPrimary.push(this.vrInsurancePrimary);
          this.vrKeyWordCarrierPrimary = this.vrInsurancePrimary.carrier_name;
        }
        if (this.vrInsuranceSecondry !== null) {
          this.vrCarrierSecondry.push(this.vrInsuranceSecondry);
          this.vrKeyWordCarrierSecondry = this.vrInsuranceSecondry.carrier_name;
        }
      })
      .then(() => {
        this.isPostBack = true;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  created() {
    //Get permissions for logged in user
    this.permissions = permissions.getPermissions("search");

    store.getters.getStates;
  },
};
